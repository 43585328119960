import React from 'react';
// import TeleportTextIcon from '@/components/ui/teleportTextIcon';

function LogoHeader() {
  return (
    <div className="flex items-center gap-3 pl-7">
      {/* <img src="https://d1ddedhodvegdk.cloudfront.net/logo.svg" alt="Logo" className="imgHeaderStyle" /> */}
      <img src="/icons/logo.png" alt="Logo" className="imgHeaderStyle" />
      <p style={{
    color: 'var(--base-white, #FFF)',
    fontFamily: '"Helvetica"',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '34px',
    letterSpacing: '0.106px'
  }}>
    Teleport
  </p>
        {/* <div className="svgWrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="98" height="24.5" viewBox="0 0 120 30" fill="none">
            <path d="M7.0269 22.8667V3.20133H0V0H17.5836V3.20133H10.5567V22.8667H7.0269Z" fill="black"/>
            <path d="M23.8003 23.324C22.1661 23.324 20.739 22.9864 19.5188 22.3113C18.2986 21.6144 17.3399 20.6453 16.6427 19.404C15.9672 18.1409 15.6295 16.6818 15.6295 15.0267V14.6347C15.6295 12.9578 15.9672 11.4987 16.6427 10.2573C17.3181 8.99422 18.255 8.02511 19.4534 7.35C20.6736 6.65311 22.079 6.30467 23.6696 6.30467C25.2166 6.30467 26.5675 6.65311 27.7223 7.35C28.8989 8.02511 29.814 8.97245 30.4677 10.192C31.1213 11.4116 31.4482 12.838 31.4482 14.4713V15.7453H19.0612C19.1048 17.1609 19.5733 18.2933 20.4666 19.1427C21.3817 19.9702 22.5148 20.384 23.8657 20.384C25.1294 20.384 26.0772 20.1009 26.7091 19.5347C27.3628 18.9684 27.8639 18.3151 28.2125 17.5747L30.9906 19.012C30.6856 19.6218 30.2389 20.2642 29.6506 20.9393C29.0841 21.6144 28.3324 22.1807 27.3955 22.638C26.4585 23.0953 25.2602 23.324 23.8003 23.324ZM19.0939 13.1647H28.0164C27.9293 11.9451 27.4935 10.9978 26.7091 10.3227C25.9247 9.62578 24.9006 9.27734 23.6369 9.27734C22.3731 9.27734 21.3382 9.62578 20.532 10.3227C19.7476 10.9978 19.2682 11.9451 19.0939 13.1647Z" fill="black"/>
            <path d="M34.1931 22.8667V0H37.5594V22.8667H34.1931Z" fill="black"/>
            <path d="M48.6345 23.324C47.0003 23.324 45.5731 22.9864 44.353 22.3113C43.1328 21.6144 42.1741 20.6453 41.4768 19.404C40.8014 18.1409 40.4637 16.6818 40.4637 15.0267V14.6347C40.4637 12.9578 40.8014 11.4987 41.4768 10.2573C42.1523 8.99422 43.0892 8.02511 44.2876 7.35C45.5078 6.65311 46.9131 6.30467 48.5037 6.30467C50.0507 6.30467 51.4016 6.65311 52.5565 7.35C53.7331 8.02511 54.6482 8.97245 55.3018 10.192C55.9555 11.4116 56.2823 12.838 56.2823 14.4713V15.7453H43.8954C43.939 17.1609 44.4074 18.2933 45.3008 19.1427C46.2159 19.9702 47.3489 20.384 48.6998 20.384C49.9636 20.384 50.9114 20.1009 51.5433 19.5347C52.1969 18.9684 52.6981 18.3151 53.0467 17.5747L55.8248 19.012C55.5197 19.6218 55.0731 20.2642 54.4848 20.9393C53.9183 21.6144 53.1665 22.1807 52.2296 22.638C51.2927 23.0953 50.0943 23.324 48.6345 23.324ZM43.9281 13.1647H52.8506C52.7635 11.9451 52.3277 10.9978 51.5433 10.3227C50.7589 9.62578 49.7348 9.27734 48.4711 9.27734C47.2073 9.27734 46.1723 9.62578 45.3661 10.3227C44.5817 10.9978 44.1024 11.9451 43.9281 13.1647Z" fill="black"/>
            <path d="M59.0272 29.4V6.762H62.3282V9.08134H62.8512C63.2652 8.36267 63.8861 7.72022 64.7141 7.154C65.5639 6.58778 66.7732 6.30467 68.3419 6.30467C69.6929 6.30467 70.9239 6.63133 72.0352 7.28467C73.1682 7.938 74.0724 8.88533 74.7479 10.1267C75.4451 11.3462 75.7937 12.8271 75.7937 14.5693V15.0593C75.7937 16.8016 75.456 18.2933 74.7806 19.5347C74.1051 20.7542 73.2009 21.6907 72.0678 22.344C70.9348 22.9973 69.6929 23.324 68.3419 23.324C67.2961 23.324 66.4136 23.1933 65.6946 22.932C64.9756 22.6707 64.3982 22.344 63.9624 21.952C63.5266 21.5382 63.178 21.1136 62.9165 20.678H62.3936V29.4H59.0272ZM67.3615 20.384C68.8431 20.384 70.0524 19.9158 70.9893 18.9793C71.9262 18.0429 72.3947 16.7036 72.3947 14.9613V14.6673C72.3947 12.9469 71.9153 11.6184 70.9566 10.682C70.0197 9.74556 68.8213 9.27734 67.3615 9.27734C65.9234 9.27734 64.725 9.74556 63.7663 10.682C62.8294 11.6184 62.3609 12.9469 62.3609 14.6673V14.9613C62.3609 16.7036 62.8294 18.0429 63.7663 18.9793C64.725 19.9158 65.9234 20.384 67.3615 20.384Z" fill="black"/>
            <path d="M86.2074 23.324C84.5951 23.324 83.157 22.9973 81.8932 22.344C80.6513 21.6689 79.6708 20.7107 78.9518 19.4693C78.2327 18.228 77.8732 16.758 77.8732 15.0593V14.5693C77.8732 12.8707 78.2327 11.4116 78.9518 10.192C79.6708 8.95067 80.6513 7.99245 81.8932 7.31733C83.157 6.64222 84.5951 6.30467 86.2074 6.30467C87.8198 6.30467 89.2579 6.64222 90.5216 7.31733C91.7854 7.99245 92.7768 8.95067 93.4958 10.192C94.2148 11.4116 94.5743 12.8707 94.5743 14.5693V15.0593C94.5743 16.758 94.2148 18.228 93.4958 19.4693C92.7768 20.7107 91.7854 21.6689 90.5216 22.344C89.2579 22.9973 87.8198 23.324 86.2074 23.324ZM86.2074 20.3187C87.6891 20.3187 88.8875 19.8504 89.8026 18.914C90.7395 17.9558 91.208 16.6382 91.208 14.9613V14.6673C91.208 12.9904 90.7504 11.6838 89.8353 10.7473C88.9202 9.78911 87.7109 9.31 86.2074 9.31C84.7476 9.31 83.5492 9.78911 82.6123 10.7473C81.6972 11.6838 81.2396 12.9904 81.2396 14.6673V14.9613C81.2396 16.6382 81.6972 17.9558 82.6123 18.914C83.5492 19.8504 84.7476 20.3187 86.2074 20.3187Z" fill="black"/>
            <path d="M97.4581 22.8667V6.762H100.759V8.65667H101.282C101.544 7.98156 101.958 7.49156 102.524 7.18667C103.112 6.86 103.831 6.69667 104.681 6.69667H106.609V9.73467H104.55C103.461 9.73467 102.568 10.0396 101.87 10.6493C101.173 11.2373 100.825 12.152 100.825 13.3933V22.8667H97.4581Z" fill="black"/>
            <path d="M114.836 22.8667C113.856 22.8667 113.072 22.5727 112.483 21.9847C111.917 21.3967 111.633 20.6127 111.633 19.6327V9.604H107.189V6.762H111.633V1.43733H115V6.762H119.804V9.604H115V19.0447C115 19.698 115.305 20.0247 115.915 20.0247H119.281V22.8667H114.836Z" fill="black"/>
            </svg>
        </div> */}
    </div>
  );
}

export default LogoHeader;