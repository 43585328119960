"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink, loggerLink, unstable_httpBatchStreamLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import SuperJSON from "superjson";
import { WagmiProvider, createConfig } from 'wagmi';
import { coinbaseWallet } from 'wagmi/connectors';
import { ConnectKitProvider, SIWEConfig, SIWEProvider, getDefaultConfig } from "connectkit";
import { SiweMessage } from 'siwe';
import { mainnet, sepolia, base } from 'viem/chains';
import { http } from 'viem';

import { type AppRouter } from "@/server/api/root";

const createQueryClient = () => new QueryClient();

let clientQueryClientSingleton: QueryClient | undefined = undefined;
const getQueryClient = () => {
  if (typeof window === "undefined") {
    // Server: always make a new query client
    return createQueryClient();
  }
  // Browser: use singleton pattern to keep the same query client
  return (clientQueryClientSingleton ??= createQueryClient());
};

export const api = createTRPCReact<AppRouter>();

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const ckConfig = getDefaultConfig({
  // Your dApps chains
  chains: [base],
  transports: {
    // RPC URL for each chain
    [base.id]: http(
      `https://base-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_API_KEY}`,
    ),
  },

  walletConnectProjectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID as string,

  appName: "Teleport",

  // // Optional App Info
  // appDescription: "Your App Description",
  // appUrl: "https://family.co", // your app's url
  // appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
})


export const wagmiConfig = createConfig(ckConfig);

const siweConfig = {
  getNonce: async () => {
    const res = await fetch(`/siwe`, { method: 'PUT' })
    if (!res.ok) throw new Error('Failed to fetch SIWE nonce')

    return res.text()
  },
  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      nonce,
      chainId,
      address,
      version: '1',
      uri: window.location.origin,
      domain: window.location.host,
      statement: 'Sign In With Ethereum to prove you control this wallet.',
    }).prepareMessage()
  },
  verifyMessage: ({ message, signature }) => {
    return fetch(`/siwe`, {
      method: 'POST',
      body: JSON.stringify({ message, signature }),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.ok)
  },
  getSession: async () => {
    const res = await fetch(`/siwe`)
    if (!res.ok) throw new Error('Failed to fetch SIWE session')

    const { address, chainId } = await res.json()
    return address && chainId ? { address, chainId } : null
  },
  signOut: () => fetch(`/siwe`, { method: 'DELETE' }).then(res => res.ok),
} satisfies SIWEConfig

export function TRPCReactProvider(props: { children: React.ReactNode }) {
  const queryClient = getQueryClient();


  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        loggerLink({
          enabled: (op) =>
            process.env.NODE_ENV === "development" ||
            (op.direction === "down" && op.result instanceof Error),
        }),
        httpBatchLink({
          transformer: SuperJSON,
          url: getBaseUrl() + "/api/trpc",
          // Needed to support session cookies
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: 'include'
            });
          }
        })
      ],
    })
  );

  async function resetRoot(){
    await fetch(`/siwe`, { method: 'DELETE' })
  }

  return (
    <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <api.Provider client={trpcClient} queryClient={queryClient}>
     
      <SIWEProvider {...siweConfig}>
          <ConnectKitProvider
          onDisconnect={resetRoot}

            options={{
              overlayBlur: 10,
              embedGoogleFonts: true,
              truncateLongENSAddress: true,
              reducedMotion: false,
              disclaimer: null,
              bufferPolyfill: true,
              walletConnectCTA: 'link'
            }}
          customTheme={{
          

              "--ck-overlay-backdrop-filter": "blur(16px)",
          
            
          }}
          >
            {props.children}
          </ConnectKitProvider>
        </SIWEProvider>
        </api.Provider>

    </QueryClientProvider>
    </WagmiProvider>

  );
}

function getBaseUrl() {
  if (typeof window !== "undefined") return window.location.origin;
  if (process.env.NEXT_PUBLIC_VERCEL_URL) return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
}
