'use client'

import Link from 'next/link'
import { Button } from '@/components/ui/button'
import LogoHeader from '@/components/ui/logoHeader';
import { useAccount } from 'wagmi'
import { ConnectKitButton, useSIWE } from 'connectkit';
import { useEffect, useState } from 'react';
import { api } from '@/trpc/react'

export default function Navbar() {

  const [buttontext,] = useState("");
  const { isSignedIn, } = useSIWE();
  const { address, isConnected } = useAccount();
  const createUser = api.user.create.useMutation()
  const {
    data: dbUser,
    isError,
    isFetching,
    refetch,
  } = api.user.getById.useQuery(
    { id: address ?? '' },
    {
      enabled: Boolean(isConnected && address),
    },
  )


  useEffect(() => {
    const getUserData = async () => {

      if (!isError && !isFetching) {
        if (!dbUser?.user?.id) {
          try {
            const { } = await createUser.mutateAsync({
              id: address ?? "",
              extWallet: address ?? "",
              username: address ?? "",
            })
          } catch (error) {
            console.error(error);
          }
        }
      }
    };
    if (isSignedIn && address) getUserData();
  }, [isSignedIn, address, isFetching, isError, dbUser, createUser, refetch]);



  return (
    <>
      <nav className="header bg-background w-full z-50 h-14 lg:h-[60px] gap-4 justify-between fixed"
      >
        <div className="mx-auto flex h-full max-w-7xl justify-between px-4 md:px-8">
          <div className="flex items-center space-x-0 md:space-x-4">
            <Link href="/" className="hover:text-primary flex items-center gap-1 px-2 text-black">
              <div className='divDown'><LogoHeader /></div>
            </Link>
          </div>
          <div className="items-center space-x-1 flex">
            <div className="lg:flex space-x-1">
              {/* <Button disabled size="sm" className="text-md blueButton">
                TEE Attestation
              </Button> */}
              {/* <Button disabled size="sm" className="text-md blueButton">
                Marketplace
              </Button> */}
              <Link href="/about">
                <Button size="sm" className="text-md aboutButton mr-2">
                  📙 About
                </Button>
              </Link>
              <Link href="/leaderboard" className="hidden">
                <Button size="sm" className="text-md aboutButton mr-2">
                  Leaderboard
                </Button>
              </Link>
              <div className="hidden lg:flex space-x-1"><ConnectKitButton label={buttontext} /></div>
            </div>
          </div>



          {/* <div className="flex items-center lg:hidden">
            <MobileMenu authenticated={isConnected} login={createWallet} logout={signOutAndDisconnect} />
          </div> */}
        </div>
      </nav>
    </>
  )
}
